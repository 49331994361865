import { useRouter } from 'next/router';
import * as React from 'react';

import Footer from './Footer';
import LandingPageNavbar from './LandingPageNavbar';
import PageContainer from './PageContainer';
import SearchInput from '../ui/Inputs/SearchInput';

interface LayoutProps {
  children: React.ReactNode;
  pageContainerClassName?: string;
  sectionClassName?: string;
  hideSearchBox?: boolean;
}

export default function Layout({
  children,
  pageContainerClassName,
  sectionClassName,
  hideSearchBox = true,
}: LayoutProps) {
  const router = useRouter();
  const ref = React.useRef<HTMLInputElement>(null);

  const doSearch = () => {
    router.push('/?q=' + encodeURIComponent(ref.current?.value || ''));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      doSearch();
    }
  };

  return (
    <>
      <div className='relative mx-auto min-h-screen max-w-[1243px] px-6 xl:px-0'>
        <LandingPageNavbar />
        {!hideSearchBox && (
          <div className='relative mx-auto mt-2 w-full max-w-3xl md:mt-6'>
            <SearchInput
              placeholder='Search certified media...'
              onKeyDown={handleKeyDown}
              onButtonClick={doSearch}
              ref={ref}
            />
          </div>
        )}
        <PageContainer
          className={pageContainerClassName}
          sectionClassName={sectionClassName}
        >
          {children}
        </PageContainer>
      </div>
      <Footer />
    </>
  );
}
