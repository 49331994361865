import React from 'react';

function EnvelopIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='31.593'
      height='24.11'
      viewBox='0 0 31.593 24.11'
      {...props}
    >
      <path
        id='Envelope'
        d='M1674.066,1622.562h-25.275a3.092,3.092,0,0,0-3.159,3.014v18.082a3.092,3.092,0,0,0,3.159,3.014h25.275a3.092,3.092,0,0,0,3.159-3.014v-18.082A3.092,3.092,0,0,0,1674.066,1622.562Zm1.048,2.014v1.77l-13.685,9.377-13.686-9.376v-1.771Zm-27.371,20.082v-15.495l12.716,8.656a1.637,1.637,0,0,0,1.939,0l12.716-8.656,0,15.495Z'
        transform='translate(-1645.632 -1622.562)'
        fill='#ff9283'
      />
    </svg>
  );
}

export default EnvelopIcon;
