import React from 'react';

export default function AppleStoreBadge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='169.822'
      height='56.766'
      viewBox='0 0 169.822 56.766'
      {...props}
    >
      <g
        id='App_Store_Badge'
        data-name='App Store Badge'
        transform='translate(-2699.614 -1453.082)'
      >
        <g id='Group_13' data-name='Group 13'>
          <g id='Group_11' data-name='Group 11'>
            <path
              id='Path_52'
              data-name='Path 52'
              d='M2855.913,1453.082H2713.145c-.52,0-1.035,0-1.554,0-.434,0-.865.011-1.3.018a18.7,18.7,0,0,0-2.844.251,9.46,9.46,0,0,0-2.7.889,9.121,9.121,0,0,0-2.3,1.673,8.888,8.888,0,0,0-1.673,2.3,9.4,9.4,0,0,0-.887,2.7,18.409,18.409,0,0,0-.254,2.841c-.013.435-.014.872-.021,1.307v32.8c.007.44.008.867.021,1.308a18.406,18.406,0,0,0,.254,2.841,9.409,9.409,0,0,0,.887,2.7,9.17,9.17,0,0,0,3.97,3.963,9.476,9.476,0,0,0,2.7.9,18.95,18.95,0,0,0,2.844.251c.439.01.87.016,1.3.016.519,0,1.034,0,1.554,0h142.768c.51,0,1.028,0,1.538,0,.432,0,.876-.006,1.308-.016a18.759,18.759,0,0,0,2.839-.251,9.63,9.63,0,0,0,2.708-.9,8.93,8.93,0,0,0,2.3-1.672,9.131,9.131,0,0,0,1.677-2.291,9.4,9.4,0,0,0,.878-2.7,19.306,19.306,0,0,0,.264-2.841c.005-.441.005-.868.005-1.308.011-.516.011-1.029.011-1.553v-29.7c0-.519,0-1.035-.011-1.549,0-.435,0-.872-.005-1.307a19.309,19.309,0,0,0-.264-2.841,9.379,9.379,0,0,0-.878-2.7,9.185,9.185,0,0,0-3.972-3.974,9.628,9.628,0,0,0-2.708-.889,18.388,18.388,0,0,0-2.839-.251c-.432-.007-.876-.016-1.308-.018-.51,0-1.028,0-1.538,0Z'
              fill='#a3a3a3'
            />
            <path
              id='Path_53'
              data-name='Path 53'
              d='M2711.6,1508.607c-.432,0-.854-.006-1.283-.016a17.859,17.859,0,0,1-2.653-.231,8.343,8.343,0,0,1-2.351-.778,7.646,7.646,0,0,1-1.982-1.442,7.559,7.559,0,0,1-1.449-1.982,8.127,8.127,0,0,1-.77-2.352,17.7,17.7,0,0,1-.237-2.661c-.009-.3-.02-1.3-.02-1.3v-32.783s.012-.981.02-1.27a17.635,17.635,0,0,1,.235-2.656,8.174,8.174,0,0,1,.772-2.359,7.618,7.618,0,0,1,1.44-1.984,7.915,7.915,0,0,1,1.99-1.451,8.239,8.239,0,0,1,2.347-.772,17.874,17.874,0,0,1,2.661-.233l1.281-.017h145.845l1.3.018a17.393,17.393,0,0,1,2.637.231,8.429,8.429,0,0,1,2.371.777,7.942,7.942,0,0,1,3.428,3.434,8.227,8.227,0,0,1,.759,2.34,18.487,18.487,0,0,1,.247,2.679c0,.4,0,.833,0,1.263.011.532.011,1.039.011,1.549v29.7c0,.516,0,1.019-.011,1.526,0,.462,0,.885-.006,1.32a17.963,17.963,0,0,1-.242,2.63,8.172,8.172,0,0,1-.766,2.37,7.81,7.81,0,0,1-1.442,1.967,7.687,7.687,0,0,1-1.986,1.451,8.331,8.331,0,0,1-2.367.78,17.7,17.7,0,0,1-2.653.231c-.415.01-.851.016-1.273.016l-1.539,0Z'
            />
          </g>
          <g id='_Group_' data-name=' Group '>
            <g id='_Group_2' data-name=' Group 2'>
              <g id='_Group_3' data-name=' Group 3'>
                <path
                  id='_Path_'
                  data-name=' Path '
                  d='M2734.765,1481.892a7.025,7.025,0,0,1,3.344-5.893,7.19,7.19,0,0,0-5.664-3.062c-2.383-.25-4.694,1.426-5.908,1.426-1.238,0-3.108-1.4-5.121-1.359a7.542,7.542,0,0,0-6.348,3.871c-2.744,4.752-.7,11.736,1.932,15.577,1.315,1.881,2.853,3.982,4.864,3.907,1.969-.082,2.7-1.255,5.08-1.255,2.354,0,3.044,1.255,5.1,1.208,2.113-.035,3.443-1.889,4.713-3.788a15.575,15.575,0,0,0,2.155-4.389A6.786,6.786,0,0,1,2734.765,1481.892Z'
                  fill='#fff'
                />
                <path
                  id='_Path_2'
                  data-name=' Path 2'
                  d='M2730.888,1470.411a6.914,6.914,0,0,0,1.582-4.954,7.033,7.033,0,0,0-4.552,2.356,6.574,6.574,0,0,0-1.623,4.77A5.816,5.816,0,0,0,2730.888,1470.411Z'
                  fill='#fff'
                />
              </g>
            </g>
            <g id='Group_12' data-name='Group 12'>
              <path
                id='Path_54'
                data-name='Path 54'
                d='M2759.647,1491.6h-6.717l-1.613,4.763h-2.846l6.363-17.623h2.956l6.363,17.623h-2.894Zm-6.021-2.2h5.324l-2.625-7.73h-.073Z'
                fill='#fff'
              />
              <path
                id='Path_55'
                data-name='Path 55'
                d='M2777.894,1489.937c0,3.993-2.137,6.558-5.362,6.558a4.357,4.357,0,0,1-4.043-2.248h-.061v6.364h-2.637v-17.1h2.553v2.137h.048a4.559,4.559,0,0,1,4.092-2.271C2775.745,1483.378,2777.894,1485.956,2777.894,1489.937Zm-2.711,0c0-2.6-1.344-4.311-3.4-4.311-2.015,0-3.371,1.746-3.371,4.311,0,2.589,1.356,4.323,3.371,4.323C2773.839,1494.26,2775.183,1492.562,2775.183,1489.937Z'
                fill='#fff'
              />
              <path
                id='Path_56'
                data-name='Path 56'
                d='M2792.036,1489.937c0,3.993-2.137,6.558-5.362,6.558a4.357,4.357,0,0,1-4.043-2.248h-.061v6.364h-2.637v-17.1h2.553v2.137h.048a4.557,4.557,0,0,1,4.091-2.271C2789.886,1483.378,2792.036,1485.956,2792.036,1489.937Zm-2.711,0c0-2.6-1.344-4.311-3.4-4.311-2.016,0-3.371,1.746-3.371,4.311,0,2.589,1.355,4.323,3.371,4.323,2.051,0,3.4-1.7,3.4-4.323Z'
                fill='#fff'
              />
              <path
                id='Path_57'
                data-name='Path 57'
                d='M2801.382,1491.451c.2,1.747,1.893,2.895,4.213,2.895,2.223,0,3.823-1.148,3.823-2.724,0-1.367-.965-2.187-3.249-2.748l-2.284-.55c-3.236-.782-4.738-2.295-4.738-4.751,0-3.041,2.65-5.129,6.412-5.129,3.724,0,6.277,2.088,6.363,5.129h-2.662c-.16-1.759-1.613-2.82-3.738-2.82s-3.578,1.074-3.578,2.637c0,1.246.928,1.979,3.2,2.54l1.941.477c3.616.855,5.118,2.308,5.118,4.885,0,3.3-2.626,5.363-6.8,5.363-3.908,0-6.547-2.017-6.717-5.205Z'
                fill='#fff'
              />
              <path
                id='Path_58'
                data-name='Path 58'
                d='M2817.9,1480.472v3.04h2.444v2.089H2817.9v7.083c0,1.1.489,1.613,1.564,1.613a8.106,8.106,0,0,0,.867-.061v2.076a7.2,7.2,0,0,1-1.465.122c-2.6,0-3.616-.977-3.616-3.469V1485.6h-1.868v-2.089h1.868v-3.04Z'
                fill='#fff'
              />
              <path
                id='Path_59'
                data-name='Path 59'
                d='M2821.754,1489.937c0-4.042,2.381-6.583,6.093-6.583s6.1,2.54,6.1,6.583-2.358,6.583-6.1,6.583S2821.754,1493.991,2821.754,1489.937Zm9.5,0c0-2.773-1.27-4.41-3.408-4.41s-3.406,1.65-3.406,4.41c0,2.784,1.269,4.409,3.406,4.409s3.408-1.625,3.408-4.409Z'
                fill='#fff'
              />
              <path
                id='Path_60'
                data-name='Path 60'
                d='M2836.117,1483.512h2.515v2.187h.061a3.064,3.064,0,0,1,3.091-2.321,4.1,4.1,0,0,1,.9.1v2.467a3.686,3.686,0,0,0-1.185-.159,2.663,2.663,0,0,0-2.749,2.956v7.621h-2.637Z'
                fill='#fff'
              />
              <path
                id='Path_61'
                data-name='Path 61'
                d='M2854.847,1492.587c-.354,2.332-2.626,3.933-5.532,3.933-3.738,0-6.058-2.5-6.058-6.522s2.333-6.644,5.947-6.644c3.555,0,5.79,2.442,5.79,6.338v.9h-9.074v.159a3.345,3.345,0,0,0,3.038,3.628,3.247,3.247,0,0,0,.418.011,2.907,2.907,0,0,0,2.967-1.807Zm-8.915-3.835h6.424a3.09,3.09,0,0,0-2.914-3.257c-.079,0-.158-.005-.238,0a3.254,3.254,0,0,0-3.272,3.233Z'
                fill='#fff'
              />
            </g>
          </g>
        </g>
        <g id='_Group_4' data-name=' Group 4'>
          <g id='Group_14' data-name='Group 14'>
            <path
              id='Path_62'
              data-name='Path 62'
              d='M2753.295,1465.473a3.746,3.746,0,0,1,4.005,3.468,3.813,3.813,0,0,1-.02.739c0,2.706-1.463,4.261-3.985,4.261h-3.059v-8.468Zm-1.743,7.27h1.6a2.661,2.661,0,0,0,2.816-2.5,3.716,3.716,0,0,0-2.305-3.554,2.737,2.737,0,0,0-.511-.021h-1.6Z'
              fill='#fff'
            />
            <path
              id='Path_63'
              data-name='Path 63'
              d='M2758.765,1470.743a3.027,3.027,0,0,1,6.027-.577,2.921,2.921,0,0,1,0,.577,3.028,3.028,0,1,1-6.027,0Zm4.73,0c0-1.386-.622-2.2-1.714-2.2s-1.713.81-1.713,2.2.617,2.2,1.713,2.2,1.714-.81,1.714-2.2Z'
              fill='#fff'
            />
            <path
              id='Path_64'
              data-name='Path 64'
              d='M2772.8,1473.94H2771.5l-1.321-4.706h-.1l-1.316,4.706h-1.295l-1.762-6.39h1.279l1.145,4.876h.094l1.314-4.876h1.21l1.314,4.876h.1l1.139-4.876h1.261Z'
              fill='#fff'
            />
            <path
              id='Path_65'
              data-name='Path 65'
              d='M2776.04,1467.55h1.214v1.015h.1a1.912,1.912,0,0,1,1.907-1.138,2.081,2.081,0,0,1,2.212,2.377v4.136h-1.262v-3.82c0-1.027-.446-1.537-1.379-1.537a1.466,1.466,0,0,0-1.532,1.4,1.39,1.39,0,0,0,.007.223v3.738h-1.262Z'
              fill='#fff'
            />
            <path
              id='Path_66'
              data-name='Path 66'
              d='M2783.477,1465.055h1.261v8.885h-1.261Z'
              fill='#fff'
            />
            <path
              id='Path_67'
              data-name='Path 67'
              d='M2786.491,1470.743a3.028,3.028,0,1,1,6.028-.577,2.921,2.921,0,0,1,0,.577,3.028,3.028,0,1,1-6.028.586A3.174,3.174,0,0,1,2786.491,1470.743Zm4.731,0c0-1.386-.623-2.2-1.715-2.2s-1.713.81-1.713,2.2.617,2.2,1.713,2.2S2791.222,1472.133,2791.222,1470.743Z'
              fill='#fff'
            />
            <path
              id='Path_68'
              data-name='Path 68'
              d='M2793.847,1472.133c0-1.15.857-1.813,2.377-1.907l1.731-.1v-.552c0-.675-.446-1.056-1.308-1.056-.7,0-1.192.259-1.332.71h-1.221c.129-1.1,1.161-1.8,2.611-1.8,1.6,0,2.506.8,2.506,2.147v4.366H2798v-.9h-.1a2.151,2.151,0,0,1-1.92,1,1.93,1.93,0,0,1-2.119-1.721A1.791,1.791,0,0,1,2793.847,1472.133Zm4.108-.546v-.534l-1.561.1c-.88.059-1.279.358-1.279.921s.5.91,1.185.91a1.507,1.507,0,0,0,1.651-1.347Z'
              fill='#fff'
            />
            <path
              id='Path_69'
              data-name='Path 69'
              d='M2800.868,1470.743c0-2.02,1.038-3.3,2.653-3.3a2.105,2.105,0,0,1,1.959,1.121h.095v-3.51h1.261v8.885h-1.209v-1.009h-.1a2.22,2.22,0,0,1-2.007,1.115C2801.9,1474.046,2800.868,1472.767,2800.868,1470.743Zm1.3,0c0,1.355.639,2.171,1.707,2.171s1.72-.828,1.72-2.166-.664-2.171-1.72-2.171-1.707.821-1.707,2.166Z'
              fill='#fff'
            />
            <path
              id='Path_70'
              data-name='Path 70'
              d='M2812.054,1470.743a3.027,3.027,0,0,1,6.027-.577,3.078,3.078,0,0,1,0,.577,3.028,3.028,0,1,1-6.027,0Zm4.73,0c0-1.386-.623-2.2-1.715-2.2s-1.713.81-1.713,2.2.617,2.2,1.713,2.2S2816.784,1472.133,2816.784,1470.743Z'
              fill='#fff'
            />
            <path
              id='Path_71'
              data-name='Path 71'
              d='M2819.773,1467.55h1.214v1.015h.094a1.914,1.914,0,0,1,1.907-1.138,2.076,2.076,0,0,1,2.212,2.377v4.136h-1.261v-3.82c0-1.027-.446-1.537-1.379-1.537a1.464,1.464,0,0,0-1.532,1.4,1.362,1.362,0,0,0,.006.223v3.738h-1.261Z'
              fill='#fff'
            />
            <path
              id='Path_72'
              data-name='Path 72'
              d='M2832.327,1465.959v1.62h1.384v1.062h-1.384v3.286c0,.67.275.963.9.963a4.263,4.263,0,0,0,.481-.029v1.05a4.139,4.139,0,0,1-.686.065c-1.4,0-1.961-.494-1.961-1.726v-3.609h-1.014v-1.062h1.014v-1.62Z'
              fill='#fff'
            />
            <path
              id='Path_73'
              data-name='Path 73'
              d='M2835.434,1465.055h1.25v3.522h.1a1.965,1.965,0,0,1,1.948-1.145,2.1,2.1,0,0,1,2.2,2.383v4.125h-1.262v-3.814c0-1.021-.476-1.538-1.367-1.538a1.493,1.493,0,0,0-1.61,1.366,1.521,1.521,0,0,0,0,.254v3.732h-1.261Z'
              fill='#fff'
            />
            <path
              id='Path_74'
              data-name='Path 74'
              d='M2848.287,1472.215a2.6,2.6,0,0,1-2.769,1.849,2.9,2.9,0,0,1-2.952-3.3,2.947,2.947,0,0,1,2.53-3.312,2.894,2.894,0,0,1,.416-.026c1.778,0,2.851,1.215,2.851,3.221v.44h-4.513v.071a1.689,1.689,0,0,0,1.542,1.825c.053,0,.107.006.16.006a1.533,1.533,0,0,0,1.521-.775Zm-4.437-2.059h3.228a1.541,1.541,0,0,0-1.424-1.652,1.477,1.477,0,0,0-.149,0,1.635,1.635,0,0,0-1.655,1.614v.042Z'
              fill='#fff'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
