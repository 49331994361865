import clsx from 'clsx';
import React from 'react';

interface PageContainerProps {
  children: React.ReactNode;
  className?: string;
  sectionClassName?: string;
}
export default function PageContainer({
  children,
  className,
  sectionClassName,
}: PageContainerProps) {
  return (
    <main>
      <div className={clsx('layout flex flex-col', className)}>
        <section
          className={clsx(
            'mx-auto my-12 pb-20 md:px-4 md:pt-0',
            sectionClassName,
            !sectionClassName && 'container'
          )}
        >
          {children}
        </section>
      </div>
    </main>
  );
}
