/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import UnstyledLink from '@/components/links/UnstyledLink';

interface Item {
  text: string;
  link?: any;
  onClick?: () => void;
}

interface HeaderPopoverItemProps {
  itemText: string;
  items: Item[];
  buttons?: boolean;
  className?: string;
}

export default function HeaderPopoverMenu({
  itemText,
  items,
  buttons = false,
}: HeaderPopoverItemProps) {
  const { t } = useTranslation('common');

  return (
    <Menu as='div' className='relative flex-shrink-0'>
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              data-testid='UserMenu'
              className={clsx(
                open
                  ? 'text-primary-500 dark:text-white'
                  : 'text-inkanText dark:text-inkanLightGray',
                'group inline-flex w-full items-center justify-between rounded-md bg-white text-xl font-normal hover:text-primary-500  focus:outline-none dark:bg-gray-900 dark:hover:text-white'
              )}
            >
              <>
                <div>{t(itemText)}</div>
                <ChevronDownIcon
                  className={clsx(
                    open
                      ? 'text-primary-500 dark:text-white'
                      : 'text-inkanText dark:text-inkanLightGray',
                    'ml-2 h-5 w-5 group-hover:text-primary-500 dark:group-hover:text-white'
                  )}
                  aria-hidden='true'
                />
              </>
            </Menu.Button>
          </div>
          <Transition
            as={React.Fragment}
            enter='transition ease-out duration-300'
            enterFrom='transform opacity-0'
            enterTo='transform opacity-100'
            leave='transition ease-in duration-100'
            leaveFrom='transform opacity-100'
            leaveTo='transform opacity-0'
          >
            <Menu.Items className='z-10 ml-6 mt-2 origin-top-right bg-white py-1 focus:outline-none dark:bg-gray-900'>
              {!buttons &&
                items.map((item) => (
                  <Menu.Item key={item.text}>
                    <UnstyledLink
                      href={item.link()}
                      className='baseline mb-1 flex !py-0.5 !pl-1 text-lg font-bold text-inkanText hover:text-primary-500 dark:text-inkanLightGray dark:hover:text-white'
                    >
                      {t(item.text)}
                    </UnstyledLink>
                  </Menu.Item>
                ))}
              {buttons &&
                items.map((item) => (
                  <Menu.Item key={item.text}>
                    <button
                      data-testid='SignOutButton'
                      onClick={item.onClick}
                      className='baseline mb-1 flex !py-0.5 !pl-1 text-lg font-bold text-inkanText hover:text-primary-500 dark:text-inkanLightGray dark:hover:text-white'
                    >
                      {t(item.text)}
                    </button>
                  </Menu.Item>
                ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
