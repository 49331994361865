import clsx from 'clsx';
import React, { forwardRef, InputHTMLAttributes } from 'react';
import { RiSearchLine } from 'react-icons/ri';

import Button from '@/components/buttons/Button';

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onButtonClick?: () => void;
}
const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ onButtonClick, ...rest }, ref) => {
    return (
      <div className='relative flex w-full flex-wrap items-stretch'>
        <div className='relative w-full'>
          <input
            {...rest}
            className={clsx(
              rest.className,
              'placeholder-blueGray-300 relative w-full rounded-lg border-[0.5px] border-inkanText bg-white px-6 py-3.5 pr-10 text-sm outline-none focus:outline-none focus:ring dark:bg-gray-900 dark:placeholder-gray-400'
            )}
            type='text'
            ref={ref}
          />
          <Button
            className='absolute inset-y-0 right-0 flex items-center rounded-l-none rounded-r-lg px-4'
            onClick={onButtonClick}
          >
            <RiSearchLine className='h-4 w-4 text-white' />
          </Button>
        </div>
      </div>
    );
  }
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
