import useTranslation from 'next-translate/useTranslation';

import { USEFUL_LINKS } from '@/constants/UsefulLinks';

import UnstyledLink from '../links/UnstyledLink';
import AppleStoreBadge from '../ui/Icons/AppleStoreBadge';
import EnvelopIcon from '../ui/Icons/EnvelopeIcon';
import GooglePlayBadge from '../ui/Icons/GooglePlayBadge';
import LocationIcon from '../ui/Icons/LocationIcon';

export default function Footer() {
  const { t, lang } = useTranslation('common');

  return (
    <footer className='flex flex-col items-center justify-center bg-inkanFooter'>
      <div className='flex w-full max-w-[1243px] flex-col items-center justify-center px-4 pb-3 pt-10 lg:px-16'>
        <div className='mb-8 flex w-full flex-col items-center justify-between sm:mb-20 lg:flex-row'>
          <h1 className='mb-4 w-full text-center text-2xl font-normal lg:mb-0 lg:w-7/12 lg:text-left lg:text-4xl'>
            {t('create-safe-channels')}
          </h1>
          <div className='flex w-auto flex-col items-center justify-end space-y-4 sm:flex-row sm:space-x-6 sm:space-y-0'>
            <UnstyledLink href={USEFUL_LINKS.ANDROID_STORE}>
              <GooglePlayBadge />
            </UnstyledLink>
            <UnstyledLink href={USEFUL_LINKS.IOS_STORE}>
              <AppleStoreBadge />
            </UnstyledLink>
          </div>
        </div>

        <div className='mb-8 flex w-full flex-col items-start justify-between space-y-4 px-2 sm:px-6 lg:flex-row lg:space-y-0'>
          <div className='w-full lg:w-3/12'>
            <p className='text-md font-medium text-black'>{t('contact')}</p>
            <div className='flex flex-col space-y-2 p-0 py-2 lg:p-2'>
              <div className='flex space-x-2'>
                <div className='flex w-10 justify-center'>
                  <EnvelopIcon />
                </div>
                <UnstyledLink
                  href='mailto:info@inkan.news'
                  className='hover:text-primary-500'
                >
                  info@inkan.news
                </UnstyledLink>
              </div>
              <div className='flex space-x-2'>
                <div className='flex w-10 justify-center'>
                  <LocationIcon width={40} />
                </div>
                <span>
                  Tech Barcelona Campus (Pier 1) Plaça de Pau Vila, 1 Bloc A
                </span>
              </div>
            </div>
          </div>
          <div className='w-full lg:w-2/12'>
            <p className='text-md font-medium text-black'>{t('legal')}</p>
            <div className='flex flex-col space-y-2 p-0 py-2 lg:p-2'>
              <UnstyledLink
                href={USEFUL_LINKS.TERMS_AND_CONDITIONS(lang)}
                className='hover:text-primary-500'
              >
                {t('terms_and_conditions')}
              </UnstyledLink>
              <UnstyledLink
                href={USEFUL_LINKS.PRIVACY_POLICY(lang)}
                className='hover:text-primary-500'
              >
                {t('privacy-policy')}
              </UnstyledLink>
            </div>
          </div>
          <div className='w-full lg:w-2/12'>
            <p className='text-md font-medium text-black'>{t('platform')}</p>
            <div className='flex flex-col space-y-2 p-0 py-2 lg:p-2'>
              <UnstyledLink
                href='/dashboard'
                className='hover:text-primary-500'
              >
                {t('dashboard')}
              </UnstyledLink>
              <UnstyledLink href='/verify' className='hover:text-primary-500'>
                {t('verifier')}
              </UnstyledLink>
              <UnstyledLink href='/' className='hover:text-primary-500'>
                {t('search-engine')}
              </UnstyledLink>
            </div>
          </div>
          <div className='w-full lg:w-2/12'>
            <p className='text-md font-medium text-black'>{t('account')}</p>
            <div className='flex flex-col space-y-2 p-0 py-2 lg:p-2'>
              <UnstyledLink href='/login' className='hover:text-primary-500'>
                {t('signin')}
              </UnstyledLink>
              <UnstyledLink href='/register' className='hover:text-primary-500'>
                {t('signup')}
              </UnstyledLink>
              <UnstyledLink href='/pricing' className='hover:text-primary-500'>
                {t('pricing')}
              </UnstyledLink>
            </div>
          </div>
        </div>

        <span className='text-sm text-inkanGray-800'>
          {new Date().getFullYear()} Inkan, {t('rights-reserved')}
          Contents SL
        </span>
      </div>
    </footer>
  );
}
