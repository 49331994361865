import React from 'react';

export default function GooglePlayBadge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='191.481'
      height='56.735'
      viewBox='0 0 191.481 56.735'
      {...props}
    >
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='0.915'
          y1='294.837'
          x2='-0.383'
          y2='294.168'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#5a9efb' />
          <stop offset='0.007' stopColor='#5a9ffb' />
          <stop offset='0.26' stopColor='#6bbcfc' />
          <stop offset='0.512' stopColor='#76d0fc' />
          <stop offset='0.76' stopColor='#7dddfd' />
          <stop offset='1' stopColor='#80e1fd' />
        </linearGradient>
        <linearGradient
          id='linear-gradient-2'
          x1='1.076'
          y1='861.842'
          x2='-1.305'
          y2='861.842'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#f5de34' />
          <stop offset='0.409' stopColor='#edbb2c' />
          <stop offset='0.775' stopColor='#e8a226' />
          <stop offset='1' stopColor='#e79a24' />
        </linearGradient>
        <linearGradient
          id='linear-gradient-3'
          x1='0.862'
          y1='572.737'
          x2='-0.501'
          y2='570.967'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#dc3d45' />
          <stop offset='1' stopColor='#a61860' />
        </linearGradient>
        <linearGradient
          id='linear-gradient-4'
          x1='-0.188'
          y1='575.465'
          x2='0.421'
          y2='574.674'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#629e73' />
          <stop offset='0.068' stopColor='#64a473' />
          <stop offset='0.476' stopColor='#76cd79' />
          <stop offset='0.801' stopColor='#81e57c' />
          <stop offset='1' stopColor='#86ef7d' />
        </linearGradient>
      </defs>
      <g
        id='Google_Play_Badge'
        data-name='Google Play Badge'
        transform='translate(-2473.252 -1453.113)'
      >
        <rect
          id='Rectangle_5'
          data-name='Rectangle 5'
          width='191.481'
          height='56.735'
          rx='7.092'
          transform='translate(2473.252 1453.113)'
        />
        <g id='Group_16' data-name='Group 16'>
          <g id='Group_15' data-name='Group 15'>
            <path
              id='Path_75'
              data-name='Path 75'
              d='M2657.641,1454.248a5.963,5.963,0,0,1,5.957,5.957v42.552a5.964,5.964,0,0,1-5.957,5.957h-177.3a5.964,5.964,0,0,1-5.957-5.957V1460.2a5.963,5.963,0,0,1,5.957-5.957h177.3m0-1.135h-177.3a7.114,7.114,0,0,0-7.092,7.092v42.552a7.113,7.113,0,0,0,7.092,7.091h177.3a7.112,7.112,0,0,0,7.092-7.091V1460.2a7.113,7.113,0,0,0-7.092-7.092Z'
              fill='#a3a3a3'
            />
          </g>
        </g>
        <g id='Group_17' data-name='Group 17'>
          <path
            id='Path_76'
            data-name='Path 76'
            d='M2540.509,1467.642a3.842,3.842,0,0,1-1.057,2.841,4.125,4.125,0,0,1-3.126,1.259,4.446,4.446,0,1,1,0-8.891,4.4,4.4,0,0,1,1.747.357,3.513,3.513,0,0,1,1.331.951l-.748.749a2.872,2.872,0,0,0-2.33-1.01,3.4,3.4,0,1,0,2.377,5.848,2.676,2.676,0,0,0,.713-1.724h-3.09V1467h4.124A3.614,3.614,0,0,1,2540.509,1467.642Z'
            fill='#fff'
            stroke='#fff'
            strokeWidth='0.2'
          />
          <path
            id='Path_77'
            data-name='Path 77'
            d='M2547.047,1464.088h-3.876v2.7h3.5v1.022h-3.5v2.7h3.876v1.046h-4.968v-8.51h4.968Z'
            fill='#fff'
            stroke='#fff'
            strokeWidth='0.2'
          />
          <path
            id='Path_78'
            data-name='Path 78'
            d='M2551.658,1471.552h-1.094v-7.464h-2.377v-1.046h5.848v1.046h-2.377Z'
            fill='#fff'
            stroke='#fff'
            strokeWidth='0.2'
          />
          <path
            id='Path_79'
            data-name='Path 79'
            d='M2558.267,1471.552v-8.51h1.092v8.51Z'
            fill='#fff'
            stroke='#fff'
            strokeWidth='0.2'
          />
          <path
            id='Path_80'
            data-name='Path 80'
            d='M2564.21,1471.552h-1.094v-7.464h-2.377v-1.046h5.848v1.046h-2.377Z'
            fill='#fff'
            stroke='#fff'
            strokeWidth='0.2'
          />
          <path
            id='Path_81'
            data-name='Path 81'
            d='M2577.658,1470.452a4.418,4.418,0,0,1-6.24,0,4.6,4.6,0,0,1,0-6.31,4.4,4.4,0,0,1,6.234.005,4.585,4.585,0,0,1,.006,6.305Zm-5.433-.712a3.274,3.274,0,0,0,4.624,0,3.628,3.628,0,0,0,0-4.886,3.274,3.274,0,0,0-4.624,0,3.633,3.633,0,0,0,0,4.886Z'
            fill='#fff'
            stroke='#fff'
            strokeWidth='0.2'
          />
          <path
            id='Path_82'
            data-name='Path 82'
            d='M2580.446,1471.552v-8.51h1.331l4.136,6.62h.047l-.047-1.64v-4.98h1.094v8.51h-1.141l-4.327-6.941h-.047l.047,1.64v5.3Z'
            fill='#fff'
            stroke='#fff'
            strokeWidth='0.2'
          />
        </g>
        <path
          id='Path_83'
          data-name='Path 83'
          d='M2569.894,1483.965a6.033,6.033,0,1,0,6.056,6.033A5.946,5.946,0,0,0,2569.894,1483.965Zm0,9.689A3.665,3.665,0,1,1,2573.3,1490,3.489,3.489,0,0,1,2569.894,1493.654Zm-13.21-9.689a6.033,6.033,0,1,0,6.056,6.033A5.946,5.946,0,0,0,2556.684,1483.965Zm0,9.689a3.665,3.665,0,1,1,3.405-3.656A3.489,3.489,0,0,1,2556.684,1493.654Zm-15.713-7.838v2.559h6.124a5.342,5.342,0,0,1-1.394,3.222,6.269,6.269,0,0,1-4.73,1.874,6.81,6.81,0,0,1,0-13.619,6.525,6.525,0,0,1,4.615,1.828l1.806-1.805a8.93,8.93,0,0,0-6.421-2.582,9.37,9.37,0,1,0,0,18.737,8.565,8.565,0,0,0,6.535-2.628,8.455,8.455,0,0,0,2.216-5.986,8.3,8.3,0,0,0-.137-1.6Zm64.264,1.988a5.608,5.608,0,0,0-5.165-3.839,5.733,5.733,0,0,0-5.689,6.033,5.9,5.9,0,0,0,5.987,6.032,6,6,0,0,0,5.027-2.673l-2.057-1.371a3.444,3.444,0,0,1-2.97,1.668,3.068,3.068,0,0,1-2.925-1.828l8.066-3.336Zm-8.226,2.011a3.308,3.308,0,0,1,3.153-3.519,2.336,2.336,0,0,1,2.239,1.28Zm-6.559,5.85h2.651v-17.732h-2.651Zm-4.341-10.352h-.092a4.18,4.18,0,0,0-3.176-1.348,6.038,6.038,0,0,0,0,12.065,4.112,4.112,0,0,0,3.176-1.371h.092v.869c0,2.308-1.234,3.541-3.222,3.541a3.336,3.336,0,0,1-3.039-2.148l-2.308.96a5.747,5.747,0,0,0,5.347,3.565c3.107,0,5.735-1.828,5.735-6.284v-10.831h-2.513Zm-3.039,8.341a3.669,3.669,0,0,1,0-7.312,3.685,3.685,0,0,1,0,7.312Zm34.581-15.721h-6.342v17.732h2.646v-6.718h3.7a5.515,5.515,0,1,0,0-11.014Zm.068,8.546h-3.764V1480.4h3.764a3.04,3.04,0,1,1,0,6.078Zm16.357-2.546a4.971,4.971,0,0,0-4.723,2.714l2.35.981a2.507,2.507,0,0,1,2.418-1.3,2.547,2.547,0,0,1,2.783,2.281v.182a5.861,5.861,0,0,0-2.76-.684c-2.532,0-5.11,1.392-5.11,3.992,0,2.373,2.076,3.9,4.4,3.9a3.732,3.732,0,0,0,3.376-1.734h.091v1.369h2.555v-6.8C2639.459,1485.689,2637.11,1483.933,2634.076,1483.933Zm-.32,9.717c-.867,0-2.076-.433-2.076-1.505,0-1.369,1.506-1.894,2.806-1.894a4.7,4.7,0,0,1,2.418.594A3.207,3.207,0,0,1,2633.756,1493.65Zm15.011-9.33-3.034,7.688h-.092l-3.148-7.688h-2.851l4.722,10.745-2.692,5.977h2.76l7.277-16.722Zm-23.839,11.345h2.646v-17.732h-2.646Z'
          fill='#fff'
        />
        <g id='Group_19' data-name='Group 19'>
          <path
            id='Path_84'
            data-name='Path 84'
            d='M2488.053,1463.805a2.843,2.843,0,0,0-.656,1.993v31.369a2.839,2.839,0,0,0,.656,1.992l.1.1,17.573-17.573v-.414l-17.573-17.573Z'
            fill='url(#linear-gradient)'
          />
          <path
            id='Path_85'
            data-name='Path 85'
            d='M2511.588,1487.549l-5.857-5.86v-.414l5.858-5.859.132.076,6.94,3.943c1.982,1.126,1.982,2.969,0,4.1l-6.94,3.943Z'
            fill='url(#linear-gradient-2)'
          />
          <path
            id='Path_86'
            data-name='Path 86'
            d='M2511.721,1487.473l-5.99-5.991-17.678,17.677c.654.692,1.732.778,2.948.087l20.72-11.773'
            fill='url(#linear-gradient-3)'
          />
          <path
            id='Path_87'
            data-name='Path 87'
            d='M2511.721,1475.491,2491,1463.718c-1.216-.69-2.294-.6-2.948.087l17.678,17.677Z'
            fill='url(#linear-gradient-4)'
          />
          <g id='Group_18' data-name='Group 18'>
            <path
              id='Path_88'
              data-name='Path 88'
              d='M2511.589,1487.341l-20.588,11.7a2.362,2.362,0,0,1-2.842.015h0l-.106.106h0l.1.1h0a2.363,2.363,0,0,0,2.842-.016l20.72-11.773Z'
              opacity='0.2'
            />
            <path
              id='Path_89'
              data-name='Path 89'
              d='M2488.053,1498.953a2.845,2.845,0,0,1-.656-1.993v.207a2.843,2.843,0,0,0,.656,1.993l.106-.106Z'
              opacity='0.12'
            />
          </g>
          <path
            id='Path_90'
            data-name='Path 90'
            d='M2518.661,1483.323l-7.072,4.018.132.132,6.94-3.943a2.488,2.488,0,0,0,1.487-2.047h0A2.643,2.643,0,0,1,2518.661,1483.323Z'
            opacity='0.12'
          />
          <path
            id='Path_91'
            data-name='Path 91'
            d='M2491,1463.926l27.66,15.715a2.643,2.643,0,0,1,1.487,1.841h0a2.489,2.489,0,0,0-1.487-2.048L2491,1463.719c-1.982-1.127-3.6-.191-3.6,2.079v.208C2487.4,1463.735,2489.019,1462.8,2491,1463.926Z'
            fill='#fff'
            opacity='0.25'
          />
        </g>
      </g>
    </svg>
  );
}
