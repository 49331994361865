import clsx from 'clsx';
import React from 'react';

export interface LogoProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'default' | 'inverted';
}

export default function LogoName({
  variant = 'default',
  className,
  ...rest
}: LogoProps) {
  const color = variant === 'inverted' ? 'fill-white' : 'fill-primary-500';

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 144.8 42.67'
      className={clsx(color, className)}
      {...rest}
    >
      <g id='Layer_2' data-name='Layer 2'>
        <g id='_02' data-name=' 02'>
          <g id='Logo'>
            <g id='Inkan'>
              <path d='M3.93,10.46h0A3.19,3.19,0,0,0,.68,13.59h0V39a3.25,3.25,0,0,0,6.5,0V13.59a3.19,3.19,0,0,0-3.25-3.13Z' />
              <path d='M3.93,0A3.86,3.86,0,0,0,0,3.79H0a3.93,3.93,0,0,0,7.86,0A3.86,3.86,0,0,0,3.93,0Z' />
              <path d='M63.22,26.84,63,26.69A.81.81,0,0,1,62.6,26a.84.84,0,0,1,.41-.7l.24-.14,11.21-9.41a3.07,3.07,0,0,0,.38-4.31l-.1-.11h0a3.35,3.35,0,0,0-4.59-.27L59.34,20.14a6.93,6.93,0,0,0-.15,11.63l11,9.66a3.34,3.34,0,0,0,4.59-.18h0a3,3,0,0,0-.07-4.31l-.12-.11Z' />
              <path d='M48.93,10.46h0a3.18,3.18,0,0,0-3.24,3.13V39a3.25,3.25,0,0,0,6.49,0V13.6a3.19,3.19,0,0,0-3.24-3.14Z' />
              <path d='M129.94,10.12a14.24,14.24,0,0,0-13.75,14.11V39a3.25,3.25,0,0,0,6.49,0V23.89a7.81,7.81,0,1,1,15.62.22V39a3.19,3.19,0,0,0,3.25,3.12h0A3.19,3.19,0,0,0,144.8,39V23.89C144.8,16.12,138.08,9.83,129.94,10.12Z' />
              <path d='M26.32,10.12A14.24,14.24,0,0,0,12.57,24.23V39a3.19,3.19,0,0,0,3.25,3.12h0A3.18,3.18,0,0,0,19.06,39h0V23.89a7.81,7.81,0,0,1,15.62.22h0V39a3.25,3.25,0,0,0,6.5,0V23.89C41.18,16.12,34.46,9.83,26.32,10.12Z' />
              <path d='M94,10.08A16.15,16.15,0,0,0,77.67,26v.35a16.13,16.13,0,0,0,16,16.29H94a18.08,18.08,0,0,0,2.65-.2A3.11,3.11,0,0,0,99.3,39a2.94,2.94,0,0,0-.24-.83,3.31,3.31,0,0,0-3.52-1.82,9.92,9.92,0,0,1-1.54.15,9.72,9.72,0,0,1-9.83-9.6v-.4a9.83,9.83,0,1,1,19.66,0h0v12.6a3.19,3.19,0,0,0,3.25,3.13h0a3.19,3.19,0,0,0,3.25-3.13h0v-13h0a16.14,16.14,0,0,0-16.25-16Z' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
