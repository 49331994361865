import React from 'react';

function LocationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17.939'
      height='27.997'
      viewBox='0 0 17.939 27.997'
      {...props}
    >
      <path
        id='Location'
        d='M1670.4,1670.492a8.97,8.97,0,0,0-17.939.005,7.615,7.615,0,0,0,1.021,4.154h-.015l6.388,14.115a1.869,1.869,0,0,0,3.116,0c.218-.22,6.436-14.112,6.436-14.112h-.006A7.681,7.681,0,0,0,1670.4,1670.492Zm-13.095-.271a4.133,4.133,0,1,1,4.133,4.111A4.121,4.121,0,0,1,1657.3,1670.221Z'
        transform='translate(-1652.459 -1661.646)'
        fill='#ff9283'
        fillRule='evenodd'
      />
    </svg>
  );
}

export default LocationIcon;
